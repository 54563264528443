var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Populate ")])]),_c('v-card-text',{staticClass:"farmer-assign-wrapper mt-3"},[_c('geko-input',{attrs:{"item":{
                    type: 'select-radio',
                    label: 'Populate By',
                    validation: ['required'],
                    option: {
                        list_pointer: {
                            label: 'label',
                            code: 'code',
                            display: ['label'],
                        },
                        default_options: [
                            {
                                label: 'Field Facilitator',
                                code: 'ff',
                            },
                            {
                                label: 'Unit Management',
                                code: 'mu',
                            },
                            {
                                label: 'Target Area',
                                code: 'ta',
                            },
                        ],
                    },
                }},model:{value:(_vm.exportBy),callback:function ($$v) {_vm.exportBy=$$v},expression:"exportBy"}}),_c('div',{class:_vm.exportBy === 'ff' ? 'd-block' : 'd-none'},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit_new('ff'))}}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[(_vm.ffList.length > 0)?_c('geko-input',{attrs:{"item":{
                                        label: 'Field Facilitator',
                                        placeholder: 'Pilih Field Facilitator',
                                        type: 'select',
                                        validation: ['required'],
                                        api: 'GetFFAllWeb_new',
                                        param: {
                                            limit: 20,
                                        },
                                        option: {

                                            default_options: _vm.ffList,
                                            list_pointer: {
                                                label: 'name',
                                                code: 'ff_no',
                                                display: ['name', 'ff_no'],
                                            },
                                        },
                                    },"disabled":_vm.ffList.length == 0},on:{"option:selected":function($event){return _vm.test($event)}},model:{value:(_vm.ff_no),callback:function ($$v) {_vm.ff_no=$$v},expression:"ff_no"}}):_vm._e(),(_vm.ffList.length == 0)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-col',[_c('h3',[_vm._v("Total Pohon yang Akan Terpopulasi")])]),_c('v-col',{staticClass:"flex d-flex justify-center",attrs:{"lg":"4"}},[_c('v-progress-circular',{attrs:{"rotate":360,"size":200,"width":20,"value":_vm.percentageFormat(_vm.configFF.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0), _vm.configFF.totalTrees),"color":`${_vm.configFF.totalTrees == 0 ? 'red' : 'green'}`}},[_c('br'),_vm._v(_vm._s(_vm.configFF.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0))+" / "+_vm._s(_vm.configFF.totalTrees)),_c('br'),_c('br'),_vm._v(" Persentase: "+_vm._s(_vm.percentageFormat(_vm.configFF.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0), _vm.configFF.totalTrees))+"% "),_c('br')])],1)],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.configFF.table.header,"items":_vm.configFF.allPopulateData,"server-items-length":_vm.configFF.totalRecord,"loading":_vm.loadingExportByFF,"items-per-page":_vm.configFF.perPage,"page":_vm.configFF.page,"footer-props":{
                                            itemsPerPageText: 'Jumlah Data Per Halaman',
                                            itemsPerPageOptions: [10, 25, 50, 100, 200],
                                            showCurrentPage: true,
                                            showFirstLastPage: true,
                                        },"show-select":true},on:{"update:page":_vm.onChangePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.data-table-select",fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"success","value":isSelected},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.configFF.selected),callback:function ($$v) {_vm.$set(_vm.configFF, "selected", $$v)},expression:"configFF.selected"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.configFF.selected.length,"variant":"success","type":"submit"}},[(!_vm.loadingExportByFF)?_c('v-icon',[_vm._v("mdi-file-tree")]):_c('v-progress-circular',{attrs:{"size":20,"color":"danger","indeterminate":""}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Populate Data")])],1)],1)],1)],1)]}}],null,true)})],1),_c('div',{class:_vm.exportBy === 'mu' ? 'd-block' : 'd-none'},[_c('ValidationObserver',{ref:"secondForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit_new('mu'))}}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[(_vm.muList.length > 0)?_c('geko-input',{attrs:{"item":{
                                        label: 'Unit Management',
                                        placeholder: 'Pilih Unit Management',
                                        type: 'select',
                                        validation: ['required'],
                                        api: 'GetManagementUnitAdmin',
                                        param: {
                                            page: 1,
                                            per_page: 10,
                                        },
                                        option: {

                                            getterKey: 'data.result',
                                            default_options: _vm.muList,
                                            list_pointer: {
                                                label: 'name',
                                                code: 'mu_no',
                                                display: ['name'],
                                            },
                                        },
                                    },"disabled":_vm.muList.length == 0},on:{"option:selected":function($event){return _vm.test2($event)}},model:{value:(_vm.mu_no),callback:function ($$v) {_vm.mu_no=$$v},expression:"mu_no"}}):_vm._e(),(_vm.muList.length == 0)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-col',[_c('h3',[_vm._v("Total Pohon yang Akan Terpopulasi")])]),_c('v-col',{staticClass:"flex d-flex justify-center",attrs:{"lg":"4"}},[_c('v-progress-circular',{attrs:{"rotate":360,"size":200,"width":20,"value":_vm.percentageFormat(_vm.configMU.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0), _vm.configMU.totalTrees),"color":`${_vm.configMU.totalTrees == 0 ? 'red' : 'green'}`}},[_c('br'),_vm._v(_vm._s(_vm.configMU.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0))+" / "+_vm._s(_vm.configMU.totalTrees)),_c('br'),_c('br'),_vm._v(" Persentase: "+_vm._s(_vm.percentageFormat(_vm.configMU.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0), _vm.configMU.totalTrees))+"% "),_c('br')])],1)],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.configMU.table.header,"items":_vm.configMU.allPopulateData,"server-items-length":_vm.configMU.totalRecord,"loading":_vm.loadingExportByMU,"items-per-page":_vm.configMU.perPage,"page":_vm.configMU.page,"footer-props":{
                                            itemsPerPageText: 'Jumlah Data Per Halaman',
                                            itemsPerPageOptions: [10, 25, 50, 100, 200],
                                            showCurrentPage: true,
                                            showFirstLastPage: true,
                                        },"show-select":true},on:{"update:page":_vm.onChangePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.data-table-select",fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"success","value":isSelected},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.configMU.selected),callback:function ($$v) {_vm.$set(_vm.configMU, "selected", $$v)},expression:"configMU.selected"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.configMU.selected.length,"variant":"success","type":"submit"}},[(!_vm.loadingExportByMU)?_c('v-icon',[_vm._v("mdi-file-tree")]):_c('v-progress-circular',{attrs:{"size":20,"color":"danger","indeterminate":""}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Populate Data")])],1)],1)],1)],1)]}}],null,true)})],1),_c('div',{class:_vm.exportBy === 'ta' ? 'd-block' : 'd-none'},[_c('ValidationObserver',{ref:"thirdForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit_new('ta'))}}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[(_vm.taList.length > 0)?_c('geko-input',{attrs:{"item":{
                                        label: 'Target Area',
                                        placeholder: 'Pilih Target Area',
                                        type: 'select',
                                        validation: ['required'],
                                        api: 'GetManagementUnitAdmin',
                                        param: {
                                            page: 1,
                                            per_page: 10,
                                        },
                                        option: {

                                            getterKey: 'data.result',
                                            default_options: _vm.taList,
                                            list_pointer: {
                                                label: 'name',
                                                code: 'area_code',
                                                display: ['name'],
                                            },
                                        },
                                    },"disabled":_vm.taList.length == 0},on:{"option:selected":function($event){return _vm.test2($event)}},model:{value:(_vm.target_area),callback:function ($$v) {_vm.target_area=$$v},expression:"target_area"}}):_vm._e(),(_vm.taList.length == 0)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-col',[_c('h3',[_vm._v("Total Pohon yang Akan Terpopulasi")])]),_c('v-col',{staticClass:"flex d-flex justify-center",attrs:{"lg":"4"}},[_c('v-progress-circular',{attrs:{"rotate":360,"size":200,"width":20,"value":_vm.percentageFormat(_vm.configTA.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0), _vm.configTA.totalTrees),"color":`${_vm.configTA.totalTrees == 0 ? 'red' : 'green'}`}},[_c('br'),_vm._v(_vm._s(_vm.configTA.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0))+" / "+_vm._s(_vm.configTA.totalTrees)),_c('br'),_c('br'),_vm._v(" Persentase: "+_vm._s(_vm.percentageFormat(_vm.configTA.selected.reduce((acc, val) => { return acc + parseInt(val.total_hidup); }, 0), _vm.configTA.totalTrees))+"% "),_c('br')])],1)],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.configTA.table.header,"items":_vm.configTA.allPopulateData,"server-items-length":_vm.configTA.totalRecord,"loading":_vm.loadingExportByTA,"items-per-page":_vm.configTA.perPage,"page":_vm.configTA.page,"footer-props":{
                                            itemsPerPageText: 'Jumlah Data Per Halaman',
                                            itemsPerPageOptions: [10, 25, 50, 100, 200],
                                            showCurrentPage: true,
                                            showFirstLastPage: true,
                                        },"show-select":true},on:{"update:page":_vm.onChangePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.data-table-select",fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"success","value":isSelected},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.configTA.selected),callback:function ($$v) {_vm.$set(_vm.configTA, "selected", $$v)},expression:"configTA.selected"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.configTA.selected.length,"variant":"success","type":"submit"}},[(!_vm.loadingExportByTA)?_c('v-icon',[_vm._v("mdi-file-tree")]):_c('v-progress-circular',{attrs:{"size":20,"color":"danger","indeterminate":""}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Populate Data")])],1)],1)],1)],1)]}}],null,true)})],1),_c('div')],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }